export type InputProps = {
	type?: string;
	name?: string;
	id?: string;
	value?: string;
	basicDivClassName?: string;
	inputDivClassName?: string;
	labelClassName?: string;
	labelName?: string;
	inputClassName?: string;
	htmlFor?: string;
	onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

export function Input(props: InputProps){

    return(
        <div className={props.basicDivClassName}>
            <label htmlFor={props.htmlFor} className={props.labelClassName}>{props.labelName}</label>
            <div className={props.inputDivClassName}>
                <input className={props.inputClassName} type={props.type} name={props.name} id={props.id} onChange={props.onChange} value={props.value} />
            </div>
        </div>
    ) 

} 