import { Outlet } from "react-router-dom";
import { Link } from "react-router-dom";

export const MainLayout = () => {
	return (
		<div className="text-amber-50 w-screen h-auto bg-bgImg bg-cover bg-no-repeat bg-center drop-shadow-lg shadow-black overscroll-auto textShadow">
			<nav className="backdrop-blur-xl font-primaryText p-5 flex flex-row items-center justify-start sm:pl-44 border-slate-700 border-b-2 shadow-md shadow-slate-700 w-screen fixed">
				<div className="font-extrabold items-center flex flex-wrap sm:flex-row justify-center">
					<Link
						className="text-2xl pl-2 flex flex-row mr-5 border-l-2 border-slate-700"
						to={"/"}
					>
						<div className="flex flex-col">
							<h2 className="border-b-2 drop-shadow-2xl border-slate-700">Tim</h2>
							<h2 className="drop-shadow-2xl">Say</h2>
						</div>
					</Link>
					<div className="flex justify-evenly text-lg">
						<Link className="drop-shadow-2xl mr-3" to={"/"}>Accueil</Link>
						<Link className="drop-shadow-2xl mr-3" to={"/projets"}>Projets</Link>
						<Link className="drop-shadow-2xl mr-3" to={"/contact"}>Contact</Link>
					</div>
				</div>
			</nav>
			<Outlet />
		</div>
	);
};
