export const PolitiqueConf = () => {
	const currentYear = new Date().getFullYear(); // Obtenir l'année actuelle

	return (
		<>
			<div className="font-primaryText font-extrabold flex flex-col items-center w-full min-h-screen justify-center py-2 sm:py-36">
				<div className="backdrop-blur-2xl justify-center w-full sm:w-9/12 rounded-xl border border-opacity-10 border-slate-900 shadow-md shadow-slate-700 py-5 leading-loose my-32 sm:my-0 px-2">
					<h1 className="text-center mt-2 text-xl underline decoration-slate-800 ">
						Politique de Confidentialité
					</h1>
					<p className="mt-2 text-base">
						Nous attachons une importance particulière au respect de la vie
						privée des utilisateurs de notre site et nous engageons à protéger
						vos données personnelles conformément aux réglementations en vigueur
						en France et en Europe. La présente politique de confidentialité
						(ci-après désignée "Politique") détaille nos pratiques concernant la
						collecte, l'utilisation, et la protection des informations que nous
						pouvons recueillir via notre site.
					</p>

					<h2 className="mt-5 text-center text-lg underline decoration-slate-800">
						Portée et Acceptation de la Présente Politique
					</h2>
					<p className="mt-2 text-base">
						Cette Politique s'applique exclusivement aux informations collectées
						sur notre site. Elle ne couvre pas les données collectées à partir
						d'autres sources, telles que Facebook®, Twitter®, YouTube® ou
						d'autres sites ou réseaux sociaux tiers. En utilisant notre site,
						vous acceptez les termes de cette Politique. Si vous n'acceptez pas
						ces termes, nous vous invitons à ne pas utiliser notre site ni à
						nous fournir des informations à caractère personnel.
					</p>

					<h2 className="mt-5 text-center text-lg underline decoration-slate-800">
						Données Collectées sur Notre Site
					</h2>
					<p className="mt-2 text-base">
						Nous recueillons et conservons certaines données à caractère
						personnel vous concernant (c’est-à-dire les informations permettant
						de vous identifier directement ou indirectement), ci-après dénommées
						"Données Personnelles", uniquement lorsque vous les communiquez
						volontairement sur notre site. Cela inclut, sans s'y limiter, votre
						nom, prénom, adresse électronique, adresse IP, etc. Nous pouvons
						vous demander de fournir des Données Personnelles dans les
						situations suivantes : lorsque vous souhaitez nous faire part de vos
						commentaires, poser des questions, ou entrer en contact avec nous.
					</p>
					<p className="mt-2 text-base">
						Lorsque vous naviguez sur notre site, nous ne collectons que les
						Données Personnelles que vous fournissez volontairement, à
						l'exception de certaines données collectées automatiquement par les
						systèmes d'information et les programmes utilisés pour le
						fonctionnement du site, dont la transmission est inhérente à
						l'utilisation d'internet. Nous ne demandons que les informations
						strictement nécessaires à votre participation aux activités de notre
						site. Le caractère obligatoire ou facultatif des informations
						demandées est clairement indiqué.
					</p>

					<h2 className="mt-5 text-center text-lg underline decoration-slate-800">
						Finalités de la Collecte des Données Personnelles
					</h2>
					<p className="mt-2 text-base">
						Chaque formulaire présent sur notre site restreint la collecte de
						données personnelles au strict nécessaire et précise
						systématiquement : les finalités de la collecte et le caractère
						obligatoire ou facultatif des informations demandées. En aucun cas,
						nous n'utiliserons vos Données Personnelles pour une finalité autre
						que celle(s) explicitement annoncée(s) sur le formulaire et
						acceptée(s) par l'utilisateur. De même, ces données ne seront ni
						transmises ni cédées à des tiers sans votre consentement explicite.
					</p>

					<h2 className="mt-5 text-center text-lg underline decoration-slate-800">
						Destinataire des Données Personnelles
					</h2>
					<p className="mt-2 text-base">
						Les Données Personnelles collectées sur notre site sont
						exclusivement destinées à notre usage. Cependant, nous pouvons être
						amenés à divulguer vos Données Personnelles aux autorités
						judiciaires, forces de l'ordre, ou autres autorités compétentes,
						lorsque la loi l'exige.
					</p>

					<h2 className="mt-5 text-center text-lg underline decoration-slate-800">
						Durée de Conservation des Données
					</h2>
					<p className="mt-2 text-base">
						Nous conservons vos Données Personnelles uniquement pour la durée
						nécessaire à l'accomplissement des finalités pour lesquelles elles
						ont été collectées, ou pour la durée requise par la législation en
						vigueur.
					</p>

					<h2 className="mt-5 text-center text-lg underline decoration-slate-800">
						Vos Droits
					</h2>
					<p className="mt-2 text-base">
						Conformément à la loi "Informatique et Libertés" du 6 janvier 1978
						(article 34), vous disposez d'un droit d'accès, de rectification, de
						modification, et de suppression des données vous concernant. Pour
						exercer ce droit, vous pouvez nous contacter par courriel à
						l'adresse suivante : [votre email]. Votre demande doit être signée,
						accompagnée d'une copie de votre pièce d'identité, et préciser
						l'adresse à laquelle vous souhaitez recevoir la réponse. Vous
						recevrez une réponse dans un délai de deux mois suivant la réception
						de votre demande.
					</p>

					<h2 className="mt-5 text-center text-lg underline decoration-slate-800">
						Sécurité des Données
					</h2>
					<p className="mt-2 text-base">
						La sécurité de vos Données Personnelles est de la plus haute
						importance pour nous. Les informations recueillies sur notre site
						sont traitées selon des protocoles sécurisés pour minimiser les
						risques d'interception ou d'accès non autorisé. Toutefois, en raison
						de la nature ouverte du réseau internet, nous ne pouvons garantir
						une sécurité absolue contre les actes de piratage ou autres accès
						non autorisés. En utilisant notre site, vous acceptez ces risques.
						Dans la limite de ce que la loi permet, nous déclinons toute
						responsabilité en cas de dommage direct ou indirect, pertes ou coûts
						découlant de l'accès non autorisé à vos Données Personnelles, de
						leur perte ou altération.
					</p>

					<p className="mt-5 text-sm text-center">
						© Copyright {currentYear}- Tous droits réservés.
					</p>
				</div>
			</div>
		</>
	);
};
