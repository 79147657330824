import { TbBrandTypescript } from "react-icons/tb";
import { LiaPhp } from "react-icons/lia";
import { IoLogoPython } from "react-icons/io5";
import { RiJavascriptLine } from "react-icons/ri";
import { FaJava } from "react-icons/fa";
import { BiLogoPostgresql } from "react-icons/bi";
import { SiMysql } from "react-icons/si";
import { FaNodeJs } from "react-icons/fa";
import { FaAngular } from "react-icons/fa";
import { FaReact } from "react-icons/fa";
import { TbBrandReactNative } from "react-icons/tb";
import { RiTailwindCssFill } from "react-icons/ri";
import { FaBootstrap } from "react-icons/fa";
import { SiVite } from "react-icons/si";

export const Accueil = () => {
	return (
		<div className="font-primaryText font-extrabold flex flex-col items-center w-full sm:h-screen justify-center">
			<div className="backdrop-blur-2xl flex flex-wrap justify-center w-10/12 sm:w-9/12 rounded-xl border border-opacity-10 border-slate-900 shadow-md shadow-slate-700 py-5 leading-loose my-32 sm:my-0">
				<div className="p-0 sm:p-2 w-4/6 align-middle mb-4">
					<h1 className="sm:text-2xl text-base leading-loose indent-2 sm:indent-14">
						Je suis développeur full stack, spécialisé dans la création de
						solutions digitales complètes. Mon expertise englobe à la fois le
						développement de sites web et d'applications mobiles pour Android et
						IOS.
					</h1>
					<h1 className="sm:text-2xl text-base leading-loose indent-2 sm:indent-14">
						Je vous propose de concevoir une solution numérique sur mesure,
						parfaitement adaptée à vos besoins.
					</h1>
				</div>
				<div className="w-4/6 my-4">
					<div className="m-auto items-center mb-5 border-b-2 border-slate-800 text-3xl p-2">
						<h1 className="text-center">Langages</h1>
					</div>
					<div className="flex flex-wrap justify-evenly">
						<div className="mr-4 flex flex-col items-center justify-center">
							<TbBrandTypescript className="text-2xl" /> <h1>TypeScript</h1>
						</div>
						<div className="mr-4 flex flex-col items-center justify-center">
							<RiJavascriptLine className="text-2xl" /> <h1>JavaScript</h1>
						</div>
						<div className="mr-4 flex flex-col items-center justify-center">
							<LiaPhp className="text-2xl" /> <h1>PHP</h1>
						</div>
						<div className="mr-4 flex flex-col items-center justify-center">
							<IoLogoPython className="text-2xl" /> <h1>Python</h1>
						</div>
						<div className="mr-4 flex flex-col items-center justify-center">
							<FaJava className="text-2xl" /> <h1>Java</h1>
						</div>
						<div className="mr-4 flex flex-col items-center justify-center">
							<SiMysql className="text-2xl" /> <h1>MySQL</h1>
						</div>
						<div className="mr-4 flex flex-col items-center justify-center">
							<BiLogoPostgresql className="text-2xl" /> <h1>PostgreSQL</h1>
						</div>
					</div>
				</div>
				<div className="w-4/6 mb-4">
					<div className="w-full m-auto text-center items-center border-b-2 border-slate-800 mb-5 text-3xl p-2">
						<h1>Frameworks</h1>
					</div>
					<div className="flex flex-wrap justify-evenly">
						<div className="mr-4 flex flex-col items-center justify-center">
							<SiVite className="text-2xl" />
							<h1>Vite</h1>
						</div>
						<div className="mr-4 flex flex-col items-center justify-center">
							<FaNodeJs className="text-2xl" />
							<h1>NodeJS</h1>
						</div>
						<div className="mr-4 flex flex-col items-center justify-center">
							<FaAngular className="text-2xl" />
							<h1>Angular</h1>
						</div>
						<div className="mr-4 flex flex-col items-center justify-center">
							<FaReact className="text-2xl" />
							<h1>React</h1>
						</div>
						<div className="mr-4 flex flex-col items-center justify-center">
							<TbBrandReactNative className="text-2xl" />
							<h1>React Native</h1>
						</div>
						<div className="mr-4 flex flex-col items-center justify-center">
							<RiTailwindCssFill className="text-2xl" />
							<h1>TailwindCSS</h1>
						</div>
						<div className="mr-4 flex flex-col items-center justify-center">
							<FaBootstrap className="text-2xl" />
							<h1>Bootstrap</h1>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
