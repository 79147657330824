import { BrowserRouter, Route, Routes } from "react-router-dom";
import { MainLayout } from "./components/Assets/MainLayaut";
import { Accueil } from "./components/Pages/Accueil";
import { Contact } from "./components/Pages/Contact";
import { Projet } from "./components/Pages/Projet";
import { PolitiqueConf } from "./components/Pages/PolitiqueConf";

function App() {
	return (
		<BrowserRouter>
			<Routes>
				<Route
					path="/"
					element={<MainLayout/>}
				>
					<Route
						index
						path="/"
						element={<Accueil/>}
					/>
					<Route
						path="/contact"
						element={<Contact/>}
					/>
					<Route
						path="/projets"
						element={<Projet/>}
					/>
					<Route 
						path="/politique-confidentialite"
						element={<PolitiqueConf/>}
					/>
				</Route>
			</Routes>
		</BrowserRouter>
	);
}

export default App;
