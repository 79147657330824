import { useEffect, useState } from "react";
import { IProjet } from "../../utility/Interface";
import { url_general } from "../../utility/url.general";
import { AiOutlineLoading3Quarters } from "react-icons/ai";

export const Projet = () => {
	const [projets, setProjets] = useState<IProjet[]>([]);
	const [loading, setLoading] = useState<boolean>(false);

	useEffect(() => {
		async function fetchProjectsData() {
			try {
				setLoading(true);
				const projectsReponse = await fetch(`${url_general}/projets`, {
					method: "GET",
					headers: {
						"Content-Type": "application/json",
					},
				});
				const projectsData = await projectsReponse.json();
				if (Array.isArray(projectsData)) {
					setProjets(projectsData);
				} else {
					setProjets([]);
				}
			} catch (e) {
				console.error("Erreur lors de chargements: ", e);
			} finally {
				setLoading(false);
			}
		}
		fetchProjectsData();
	}, []);

	if (loading) {
		return (
			<div className="w-screen h-screen bg-slate-900 flex items-center justify-center">
				<div className="animate-spin">
					<AiOutlineLoading3Quarters />
				</div>
			</div>
		);
	}

	return (
		<div className="font-primaryText font-extrabold flex flex-col min-h-screen items-center w-full sm:h-screen justify-center">
			<div className="backdrop-blur-2xl flex flex-wrap justify-center w-10/12 sm:w-9/12 rounded-xl border border-opacity-10 border-slate-900 shadow-md shadow-slate-700 py-5 leading-loose my-32 sm:my-0">
				{Array.isArray(projets) &&
					projets.map((projet, i) => (
						<div
							key={i}
							className="rounded-lg backdrop-blur-xl bg-opacity-50 p-2 w-auto"
						>
							<div className="max-w-sm m-2.5 text-left flex-col items-center">
								<div className="rounded-t-lg mb-2">
									<img
										className="h-56"
										src={projet.photo}
										alt={projet.titre}
									/>
								</div>
								<div className="flex-1 m-2">
									<h1 className="text-xl font-bold text-primaryText">
										{projet.titre}
									</h1>
									<h2 className="text-md text-primaryText">
										{projet.description}
									</h2>
									<a
										href={projet.url}
										className="text-lg font-bold text-primaryText"
										target="_blank"
										rel="noopener noreferrer"
									>
										Visiter le site
									</a>
								</div>
							</div>
						</div>
					))}
			</div>
		</div>
	);
};
