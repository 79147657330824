import { ChangeEvent, useEffect, useState } from "react";
import { url_general } from "../../utility/url.general";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { Input } from "../Assets/Input";
import { Link } from "react-router-dom";

export const Contact = () => {
	const [nom, setNom] = useState("");
	const [prenom, setPrenom] = useState("");
	const [mail, setMail] = useState("");
	const [tel, setTel] = useState("");
	const [objet, setObjet] = useState("");
	const [message, setMessage] = useState("");
	const [loading, setLoading] = useState<boolean>(false);
	const [msg, setMsg] = useState("");
	const [ok, setOk] = useState<boolean>(false);

	const handleChangeNom = (event: ChangeEvent<HTMLInputElement>) => {
		setNom(event.target.value);
	};

	const handleChangePrenom = (event: ChangeEvent<HTMLInputElement>) => {
		setPrenom(event.target.value);
	};

	const handleChangeMail = (event: ChangeEvent<HTMLInputElement>) => {
		setMail(event.target.value);
	};

	const handleChangeTel = (event: ChangeEvent<HTMLInputElement>) => {
		setTel(event.target.value);
	};

	const handleChangeObjet = (event: ChangeEvent<HTMLInputElement>) => {
		setObjet(event.target.value);
	};

	const handleChangeMessage = (event: ChangeEvent<HTMLTextAreaElement>) => {
		setMessage(event.target.value);
	};

	const submitForm = async () => {
		if (!prenom && !nom && !mail && !message) {
			setMsg(
				"Les champs 'Prénom', 'Nom', 'Mail et 'Message' sont obligatoires."
			);
			setOk(false);
			return;
		}
		const formData = {
			prenom: prenom,
			nom: nom,
			mail: mail,
			tel: tel,
			objet: objet,
			message: message,
		};
		const enregistrement = await fetch(`${url_general}/message`, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify(formData),
		});

		await enregistrement.json();
		if (enregistrement.ok) {
			setMsg("Le message était envoyé");
			setOk(true);
			setNom("");
			setPrenom("");
			setMail("");
			setTel("");
			setObjet("");
			setMessage("");
		} else {
			setOk(false);
			setMsg(
				"Le message n'était pas envoyé soit à cause d'un souci de reseau, soit parce que vous essayé de faire n'importe quoi alors tant pis"
			);
		}
	};

	const handleSubmitButton = (event: React.FormEvent) => {
		event.preventDefault();
		submitForm();
	};

	// nom prenom mail tel objet message

	if (loading) {
		return (
			<div className="w-screen h-screen bg-slate-900 flex items-center justify-center">
				<div className="animate-spin">
					<AiOutlineLoading3Quarters />
				</div>
			</div>
		);
	}

	return (
		<div className="font-primaryText font-extrabold flex flex-col min-h-screen items-center w-full justify-center">
			<div className="backdrop-blur-2xl flex flex-wrap justify-center w-10/12 sm:w-9/12 rounded-xl border border-opacity-10 border-slate-900 shadow-md shadow-slate-700 py-5 leading-loose my-32 sm:my-0 p-2">
				<form className="w-10/12">
                <div className="m-auto">

						<h1 className="text-center">Contactez-nous pour un devis ou tout autre rensegnement</h1>
                </div>
					
					<div className="w-full">
						<Input
							basicDivClassName="basis-1/3"
							htmlFor="Nom"
							labelClassName="block text-sm font-semibold leading-6 mt-5"
							labelName="Nom"
							inputDivClassName="mt-2.5"
							inputClassName="text-black block w-full rounded-md border-0 px-3.5 shadow-sm ring-1 ring-inset focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6"
							id="Nom"
							type="text"
							name="Nom"
							value={nom}
							onChange={handleChangeNom}
						/>

						<Input
							basicDivClassName="basis-1/3"
							htmlFor="Prenom"
							labelClassName="block text-sm font-semibold leading-6 mt-5"
							labelName="Prenom"
							inputDivClassName="mt-2.5"
							inputClassName="text-black block w-full rounded-md border-0 px-3.5 shadow-sm ring-1 ring-inset focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6"
							id="Prenom"
							type="text"
							name="Prenom"
							value={prenom}
							onChange={handleChangePrenom}
						/>

						<Input
							basicDivClassName="basis-1/3"
							htmlFor="Tel"
							labelClassName="block text-sm font-semibold leading-6 mt-5"
							labelName="Tel"
							inputDivClassName="mt-2.5"
							inputClassName="text-black block w-full rounded-md border-0 px-3.5 shadow-sm ring-1 ring-inset focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6"
							id="Tel"
							type="text"
							name="Tel"
							value={tel}
							onChange={handleChangeTel}
						/>
						<Input
							basicDivClassName="basis-1/3"
							htmlFor="Mail"
							labelClassName="block text-sm font-semibold leading-6 mt-5"
							labelName="Mail"
							inputDivClassName="mt-2.5"
							inputClassName="text-black block w-full rounded-md border-0 px-3.5 shadow-sm ring-1 ring-inset focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6"
							id="Mail"
							type="text"
							name="Mail"
							value={mail}
							onChange={handleChangeMail}
						/>
						<Input
							basicDivClassName="basis-1/3"
							htmlFor="Objet"
							labelClassName="block text-sm font-semibold leading-6 mt-5"
							labelName="Objet"
							inputDivClassName="mt-2.5"
							inputClassName="text-black block w-full rounded-md border-0 px-3.5 shadow-sm ring-1 ring-inset focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6"
							id="Objet"
							type="text"
							name="Objet"
							value={objet}
							onChange={handleChangeObjet}
						/>
						<div className="basis-1/3">
							<label
								className="block text-sm font-semibold leading-6 mt-5"
								htmlFor="Message"
							>
								Message
							</label>
							<textarea
								className="text-black block w-full h-32 mt-5 rounded-md border-0 px-3.5 py-3.5 shadow-sm ring-1 ring-inset focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6 overflow-auto resize-none"
								id="Message"
								name="Message"
								value={message}
								onChange={handleChangeMessage}
							/>
						</div>
					</div>
					<button
						className="mx-auto mt-5 w-full flex justify-center items-center px-3.5 py-2.5 text-sm font-semibold shadow-sm bg-primaryNav backdrop-blur-sm bg-opacity-50 hover:bg-primaryText hover:text-primaryNav hover:backdrop-blur-sm hover:bg-opacity-50 hover:border-primaryNav hover:border-2 duration-500 ease-in-out group h-12 border-2 rounded-full"
						onClick={handleSubmitButton}
					>
						Envoyer
					</button>
				</form>
				<div className="mt-10 border border-slate-500 rounded-lg p-2">
					<p>
						En soumettant ce formulaire, vous consentez à la conservation de vos
						informations personnelles conformément à notre <Link to="/politique-confidentialite" className="underline hover:text-slate-600 underline-offset-8">politique de
						confidentialité</Link>.
					</p>
				</div>
			</div>
		</div>
	);
};
